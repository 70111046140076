<template>
  <div class="content">
    <PageHeader :title="$t('exported-sms.header')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <div
              v-if="exportSms.length == 0 && fetched"
              class="text-center table-placeholder"
            >
              <br />
              <i class="fas fa-chart-bar font-size-80"></i>
              <h5 class="card-title m-t-20">Nenhum relatório encontrado</h5>
            </div>
            <table
              v-if="fetched && exportSms.length > 0"
              class="table export-table"
            >
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$tc('generic-str.name', 1)}}</th>
                  <th scope="col">{{$t('generic-str.date')}}</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in exportSms" :key="item.id">
                  <td data-label="Nome">{{ item.name }}</td>
                  <td data-label="Data">{{ item.created_at }}</td>
                  <td data-label="Status">
                    <span
                      v-if="item.status == 'queued'"
                      class="badge badge-pill badge-light"
                      >{{$t('generic-str.status.lbl-queue')}}</span
                    >
                    <span
                      v-if="item.status == 'in_progress'"
                      class="badge badge-pill badge-warning"
                      >Em Processo</span
                    >
                    <span
                      v-if="item.status == 'failed'"
                      class="badge badge-pill badge-danger"
                      >{{$t('generic-str.status.lbl-failure')}}</span
                    >
                    <span
                      v-else-if="item.status == 'done'"
                      class="badge badge-pill badge-success"
                      >{{$t('generic-str.status.lbl-exported')}}</span
                    >
                  </td>
                  <td data-label>
                    <button
                      v-if="item.status == 'done'"
                      :class="{
                        'qt-loader qt-loader-mini qt-loader-right':
                          exportSms[i].loading,
                      }"
                      :disabled="exportSms[i].loading"
                      class="btn btn-success btn-sm"
                      @click="exportFileSMS(item.id, item.name, i)"
                    >
                      <i class="fas fa-download color-white"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="!fetched"
              class="loading-black qt-block-ui"
              style="padding: 80px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/sms.service';
import WhatsAppService from '@/services/whatsapp.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      toDay: {},
      fetched: false,
      messages: [],
      paginate: {},
      page: 1,
      exportSms: [],
      allExports: [],
      filterInput: '',
      form: {
        id: null,
        startDate: null,
        endDate: null,
        from: null,
        to: null,
        status: null,
        page: 1,
      },
    };
  },
  created() {
    this.fetch(1);
  },
  mounted() {
    this.toDay = {
      date: new Date(),
    };
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      WhatsAppService.reportsExport().then(
        (response) => {
          response.data.forEach((expt) => {
            const nExport = expt;
            nExport.loading = false;
            this.exportSms.push(nExport);
          });
          this.fetched = true;
        },
        (error) => {
          this.content = error;
          this.fetched = true;
        },
      );
    },
    fileExport(response, name) {
      if (response.headers['content-type'] === 'application/zip') {
        name = `${name}.zip`;
      } else {
        name = `${name}.csv`;
      }
      const data = response.data;
      const blob = new Blob([data], { type: response.headers['content-type'] });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    exportFileSMS(id, name, i) {
      this.exportSms[i].loading = true;
      WhatsAppService.downlodExport(id).then(
        (response) => {
          this.fetched = true;
          this.exportSms[i].loading = false;
          // Swal.hide();
          this.fileExport(response, name);
          this.fetched = true;
        },
        (error) => {
          this.fetched = true;
          this.exportSms[i].loading = false;
          this.content = error;
          this.$toast.show({
            title: this.$t('sms.infos.filters.status.lbl-error'),
            content: error.response.data.message,
            type: 'danger',
          });
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
}
.m-top-10 {
  margin-top: 10px;
}
tbody tr {
  cursor: default;
}
</style>
